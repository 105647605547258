import React from 'react';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';

import { elections as el, links } from '../../helpers/constants';
import { labels, t } from '../../helpers/dictionary';

// import iconLanding from '../../../public/img/icons/92white_money_flow.png';
import iconLanding from '../../../public/img/icons/92white_financing.png';
import iconEU from '../../../public/img/icons/92white_eu.png';
import iconNational from '../../../public/img/icons/92white_national.png';
import iconPresident from '../../../public/img/icons/92white_president.png';
import iconRegional from '../../../public/img/icons/92white_regional.png';

function IconContent({ icon, label }) {
    return (
        <>
            <img src={icon} className="me-3 me-sm-0" />
            <span className="text-center text-white mt-sm-2">{t(label)}</span>
        </>
    );
}

function SiteNavigator({ site }) {
    return (
        <div className="site-navigator mb-4">
            <h2 className="mb-3">{t(labels.sitesTitle)}</h2>
            <Row className="gx-0">
                <Col xs={12} sm={4} lg>
                    <a
                        href="/"
                        className={`d-flex flex-sm-column align-items-center justify-content-sm-between h-100 px-3 py-2${site ? '' : ' bg-primary'}`}
                    >
                        <img src={iconLanding} className="me-3 me-sm-0" />
                        <span className="text-center text-white mt-sm-2">
                            {t(labels.sites.root)}
                        </span>
                    </a>
                </Col>
                {[
                    [el.s22, iconRegional, labels.sites.regional],
                    [[el.n23, el.n20], iconNational, labels.sites.national],
                    [[el.p24, el.p19], iconPresident, labels.sites.president],
                    [el.e24, iconEU, labels.sites.european],
                ].map(([key, icon, label]) => {
                    const isDropdown = Array.isArray(key);
                    const current =
                        site === key || (isDropdown && key.includes(site));
                    return (
                        <Col key={key} xs={12} sm={4} lg>
                            {isDropdown ? (
                                <Dropdown
                                    className="h-100"
                                    drop="down-centered"
                                >
                                    <Dropdown.Toggle
                                        className={`d-flex flex-sm-column align-items-center justify-content-sm-between h-100 w-100 px-3 py-2${current ? ' bg-primary' : ''}`}
                                        variant="transparent"
                                    >
                                        <IconContent
                                            icon={icon}
                                            label={label}
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {key.map((di) => (
                                            <Dropdown.Item
                                                key={di}
                                                href={links[di]}
                                                active={site === di}
                                            >
                                                {t(labels.elections[di])}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <a
                                    href={links[key]}
                                    className={`d-flex flex-sm-column align-items-center justify-content-sm-between h-100 px-3 py-2${current ? ' bg-primary' : ''}`}
                                >
                                    <IconContent icon={icon} label={label} />
                                </a>
                            )}
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}

export default SiteNavigator;
